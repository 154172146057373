"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAddress = exports.getName = exports.getRecord = void 0;
const VET_RESOLVER_UTILS = "0xA11413086e163e41901bb81fdc5617c975Fa5a1A";
const ABI = {
    getAddresses: {
        "inputs": [
            {
                "internalType": "string[]",
                "name": "names",
                "type": "string[]"
            }
        ],
        "name": "getAddresses",
        "outputs": [
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    getNames: {
        "inputs": [
            {
                "internalType": "address[]",
                "name": "addresses",
                "type": "address[]"
            }
        ],
        "name": "getNames",
        "outputs": [
            {
                "internalType": "string[]",
                "name": "names",
                "type": "string[]"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
};
function getRecord(name, connex) {
    return __awaiter(this, void 0, void 0, function* () {
        const { decoded: { addresses } } = yield connex.thor.account(VET_RESOLVER_UTILS).method(ABI.getAddresses).call([name]);
        if (Array.isArray(addresses) && addresses[0]) {
            return addresses[0];
        }
        throw new Error(`No address found for ${name}`);
    });
}
exports.getRecord = getRecord;
function getName(address, connex) {
    return __awaiter(this, void 0, void 0, function* () {
        const { decoded: { names } } = yield connex.thor.account(VET_RESOLVER_UTILS).method(ABI.getNames).call([address]);
        if (Array.isArray(names) && names[0]) {
            return names[0];
        }
        return '';
    });
}
exports.getName = getName;
function getAddress(addressOrName, connex) {
    return __awaiter(this, void 0, void 0, function* () {
        return String(addressOrName).includes('.') ? yield getRecord(addressOrName, connex) : addressOrName;
    });
}
exports.getAddress = getAddress;
